.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-footer {
  /* background-color: #282c34; */
  background-color: #192734;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10%;
}

.App-link {
  color: #D79C48;
}

body {
  background-color: #192734;
  color: white;
  font-family: monospace;
  /* font-size: calc(10px + 3vmin); */

  min-height: 50vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

}

h1 {
  font-size: calc(10px + 10vmin);
}


h3 {
  font-size: calc(10px + 4vmin);
}


